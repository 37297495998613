import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";
import DashboardLayout from "src/layouts/DashboardLayout";
import LoginLayout from "src/layouts/LoginLayout";

export const routes = [
  {
    exact: true,
    path: "/dashboard",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard")),
  },
  {
    exact: true,
    path: `/division`,
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Division/DivisionList")
    ),
  },
  {
    exact: true,
    path: "/division/create",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Division/CreateDivision")
    ),
  },
  {
    exact: true,
    path: "/division/update",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Division/CreateDivision")
    ),
  },
  {
    exact: true,
    path: "/division/view",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Division/Divisiondetail")
    ),
  },
  {
    exact: true,
    path: `/warehouse`,
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Warehouse/WarehouseList")
    ),
  },
  {
    exact: true,
    path: `/warehouseDV`,
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Warehouse/WarehouseDivisionView")
    ),
  },
  {
    exact: true,
    path: "/warehouse/section",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Warehouse/CreateWarehouse")
    ),
  },
  {
    exact: true,
    path: "/warehouse/viewSite",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Warehouse/ViewWarehouse")
    ),
  },
  {
    exact: true,
    path: "/inventory/history",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/inventory/InventoryHistoryList")
    ),
  },
  {
    exact: true,
    path: "/inventory/detail",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/inventory/ViewInventory")
    ),
  },
  {
    exact: true,
    path: "/inventory/history/view",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/inventory/viewInventoryHistory")
    ),
  },
  {
    exact: true,
    path: "/division/view/client/view",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Division/viewClient")
    ),
  },
  {
    exact: true,
    path: "/logshipment/detail",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/logshipment/ViewLogShipment")
    ),
  },
  {
    exact: true,
    path: "/search/results",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Result/ResultPage")
    ),
  },
  {
    exact: true,
    path: "/client/user/create",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Division/CreateClientUser")
    ),
  },
  {
    exact: true,
    path: `/parts`,
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/parts/PartsList")),
  },
  {
    exact: true,
    path: `/generate-packing-slip`,
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/logshipment/PrintPackingSlip")
    ),
  },
  {
    exact: true,
    path: "/parts/create",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/parts/CreatePart")),
  },
  {
    exact: true,
    path: `/inventory`,
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/inventory/InventoryOverviewList")
    ),
  },
  {
    exact: true,
    path: `/activityLogs`,
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/ActivityLog/ActivityLog")
    ),
  },
  {
    exact: true,
    path: `/inventory-over-view`,
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/inventory/InventoryList")
    ),
  },
  {
    exact: true,
    path: `/inventory/myinventory`,
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/inventory/MyInventoryList")
    ),
  },
  {
    exact: true,
    path: `/inventoryTechnician`,
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Technician/TechnicianInventoryView")
    ),
  },
  {
    exact: true,
    path: `/reconcileTechnician`,
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Technician/TechnicianReconcileView")
    ),
  },
  {
    exact: true,
    path: `/logShipmentTechnician`,
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Technician/TechnicianLogShipmentView")
    ),
  },
  {
    exact: true,
    path: `/reconcilation/addReport`,
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Technician/AddReconcileTechnician")
    ),
  },
  {
    exact: true,
    path: `/inventoryClient`,
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/inventory/InventoryClientView")
    ),
  },
  {
    exact: true,
    path: "/inventory/create",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/inventory/CreateInventory")
    ),
  },
  {
    exact: true,
    path: "/inventory/create",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/inventory/CreateInventory")
    ),
  },
  {
    exact: true,
    path: "/technician",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Technician/TechnicianList")
    ),
  },

  {
    exact: true,
    path: "/technician/techniciannamelist",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Technician/TechnicianLogicsticList")
    ),
  },
  {
    exact: true,
    path: "/technician/techniciannamelist/view",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Technician/ViewTechnicianDetails")
    ),
  },

  {
    exact: true,
    path: "/reconcilation",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/ReconcileInventory/ReconcileInventoryList"
      )
    ),
  },
  {
    exact: true,
    path: "/reconcilation/add",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/ReconcileInventory/CreateReconcile")
    ),
  },
  {
    exact: true,
    path: "/reconcilation/view",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/ReconcileInventory/ViewReconcileReport")
    ),
  },
  {
    exact: true,
    path: "/logshipment",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/logshipment/LogshipmentList")
    ),
  },
  {
    exact: true,
    path: "/logshipment/create",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/logshipment/CreateShipment")
    ),
  },

  {
    exact: true,
    path: "/custom",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/CustomReport/CustomeReport")
    ),
  },
  {
    exact: true,
    path: "/custom/sendReport",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/CustomReport/SendReport")
    ),
  },
  {
    exact: true,
    path: "/custom/sendReport/AllSchedule",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/CustomReport/AllSchedule")
    ),
  },
  {
    exact: true,
    path: "/custom/sendReport/AllSchedule/UpdateReport",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/CustomReport/UpdateReport")
    ),
  },
  {
    exact: true,
    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/LogIn")),
  },
  {
    exact: true,
    path: "/Login",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/LogIn")),
  },

  {
    exact: true,
    path: "/forget-password",
    guard: true,
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/forget-password/index")),
  },
  {
    exact: true,
    path: "/reset-password",
    guard: true,
    layout: LoginLayout,
    component: lazy(() =>
      import("src/views/auth/reset-password/resetPassword")
    ),
  },
  {
    exact: true,
    path: "/Verify-otp",
    guard: true,
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/reset-password/VerifyOtp")),
  },
  {
    exact: true,
    path: "/terns and conditions",
    // guard:true,
    // layout: LoginLayout,
    component: lazy(() => import("src/component/TermAndConditions")),
  },
  {
    exact: true,
    path: "/privacy-policy",
    // guard:true,
    // layout: LoginLayout,
    component: lazy(() => import("src/component/PrivacyPolicy")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
